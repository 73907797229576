<template>
  <el-dialog :title="rolename" :visible.sync="roleVisible" width="68%" :before-close="roleClose">
    <div class="dialogOrg">
      <div class="dialogOrg-left">
        <div class="hint">已绑定员工</div>
      </div>
      <div class="dialogOrg-right">
        <div class="hint">未绑定员工</div>
      </div>
    </div>
    <div class="dialogOrg">
      <div class="dialogOrg-left">
        <el-table
          :data="leftlogData"
          style="width: 100%"
          border
          :row-style="iRowStyle"
          :cell-style="iCellStyle"
          :header-row-style="iHeaderRowStyle"
          :header-cell-style="iHeaderCellStyle"
        >
          <el-table-column prop="work_no" label="工号" width="100" align="center"></el-table-column>
          <el-table-column prop="user_name" label="姓名" width="120" align="center"></el-table-column>
          <el-table-column prop="type_name" label="职级" align="center"></el-table-column>
          <el-table-column prop="mobile" label="手机号" align="center"></el-table-column>

          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="Nobingding(scope.$index, scope.row)">取消绑定</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="dialogOrg-right">
        <div>
          <div class="shai">
            <div class="lianhe">
              <el-select v-model="chenkbox">
                <el-option v-for="item in chenlist" :key="item.value" :label="item.name" :value="item.value" @click.native="typsCheng()"></el-option>
              </el-select>
              <!-- <el-input v-model="inp" :placeholder="placeholder" ></el-input> -->
              <el-autocomplete
                clearable
                :trigger-on-focus="false"
                :placeholder="placeholder"
                v-model="keyword"
                :fetch-suggestions="handCounts"
                value-key="user_name"
                @select="handleSelect($event, '人')"
                @clear="qingchu"
              ></el-autocomplete>
            </div>
          </div>

          <div class="shai"></div>

          <el-table
            :data="rightlogData"
            style="width: 100%"
            border
            :row-style="iRowStyle"
            :cell-style="iCellStyle"
            :header-row-style="iHeaderRowStyle"
            :header-cell-style="iHeaderCellStyle"
          >
            <el-table-column prop="work_no" label="工号" width="100" align="center"></el-table-column>
            <el-table-column prop="user_name" label="姓名" width="120" align="center"></el-table-column>
            <el-table-column prop="type_name" label="职级" align="center"></el-table-column>
            <el-table-column prop="mobile" label="手机号" align="center"></el-table-column>

            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-button type="text" @click="bingding(scope.$index, scope.row)">绑定</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="roleClose">取 消</el-button>
      <el-button type="primary" @click="Open">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { Orguserlist, Orgbinduesr, Nodeuserlist, Nodeuserusual, Nodebind } from '../../../../api/personnel.js'
import Fenye from '../../../../components/Fenye.vue'
export default {
  components: {
    Fenye
  },
  name: 'Role',
  props: {
    roleVisible: Boolean,
    rolename: String,
    roleId: Number
  },
  data() {
    return {
      user_name: '',
      word: '',
      keyword: '',
      roleIds: '',
      chenkbox: '1',
      match_type: 'name',
      chaliang: [],
      placeholder: '请输入员工姓名',
      leftlogData: [],
      rightlogData: [],
      Dropslist: [],
      arrs: [],
      chenlist: [
        { name: '姓名', value: '1' },
        { name: '工号', value: '2' },
        { name: '手机号', value: '3' }
      ],
      pageData: {
        page: 1,
        size: 10,
        count: 0
      }
    }
  },
  methods: {
    // 取消绑定
    Nobingding(index, row) {
      // console.log(index, row)

      this.$confirm('确定解除人员绑定吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.rightlogData.unshift(row)
          this.leftlogData.splice(index, 1)
          this.arrs.push(row)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消解绑'
          })
        })
    },

    // 绑定
    bingding(index, row) {
      // console.log(index, row)
      this.leftlogData.unshift(row)
      this.rightlogData.splice(index, 1)
    },
    // 弹窗取消
    roleClose() {
      this.keyword = ''
      this.chenkbox = '1'
      this.user_name = ''
      this.match_type = 'name'
      this.arrs = []
      this.pageData.size = 10
      this.pageData.page = 1
      this.rightlogData = []
      this.leftlogData = []
      this.chaliang = []
      this.$emit('roleClose')
    },

    // 弹窗确定
    Open() {
      this.$confirm('确定提交人员变动吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let chali = JSON.parse(this.chaliang)
          // 已绑定的id’
          let cha_id = []
          chali.forEach(item => {
            cha_id.push(item.user_id)
          })
          // console.log(cha_id, 'initID')
          // 修改后的id
          let left_id = []
          this.leftlogData.forEach(sos => {
            left_id.push(sos.user_id)
          })
          // console.log(left_id, '左侧现有ID')
          let res = [] // 解绑的数据id
          const getAbsentValues = (cha_id, left_id) => {
            res = cha_id.filter(el => {
              return !left_id.find(obj => {
                return el === obj
              })
            })
            return res
          }
          getAbsentValues(cha_id, left_id)

          // console.log(res, '解绑的数据id')

          for (let i = 0; i < left_id.length; i++) {
            for (let j = 0; j < cha_id.length; j++) {
              if (left_id[i] === cha_id[j]) {
                left_id.splice(i, 1)
              }
            }
          }
          // console.log(left_id, '新增人员id') // 新增岗位人员

          if (res.length === 0 && left_id.length === 0) {
            // console.log('没有操作')
            this.$emit('roleClose')
            // 没有操作关闭弹窗
          } else {
            if (res.length !== 0) {
              let resForm = {
                node_id: this.roleIds,
                status: 0,
                users: res
              }
              this.OrgbinduesrNo(resForm)
              // console.log('解绑有ID,走没走')
            } else {
              console.log('解绑的数据id为空')
            }

            if (left_id.length !== 0) {
              let leftForm = {
                node_id: this.roleIds,
                status: 1,
                users: left_id
              }
              this.OrgbinduesrOk(leftForm)
              // console.log('绑订有ID,走没走')
            } else {
              console.log('绑定数据id为空')
            }
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消变动'
          })
        })
    },

    // 单选
    typsCheng() {
      // console.log(this.chenkbox)
      this.keyword = ''
      if (this.chenkbox == 2) {
        this.placeholder = '请输入员工工号'
        this.match_type = 'workno'
      } else if (this.chenkbox == 3) {
        this.match_type = 'mobile'
        this.placeholder = '请输入员工手机号'
      } else {
        this.placeholder = '请输入员工姓名'
        this.match_type = 'name'
      }
    },

    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.Orguserlists()
    },

    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.Orguserlists()
    },

    // 搜人
    handCounts(queryString, cb) {
      this.Drops(queryString, cb)
    },

    // 清除搜索
    qingchu() {
      // this.arrs =[]
      this.user_name = ''
      this.Orguserlists()
    },

    // 人搜索选中
    handleSelect(e) {
      // console.log(e)
      this.user_name = e.user_name
      this.Orguserlists()
    },

    ///////////请求区////////////

    // 模糊搜索
    async Drops(keyword, cd) {
      const { data } = await Nodeuserusual({ keyword, waiting: 1, node_id: this.roleIds, check_all: 1, match_type: this.match_type })
      let date = data.data.list
      this.leftlogData.forEach(item => {
        date.forEach((ele, i) => {
          if (item.user_id == ele.user_id) {
            // delete date[i]
            date.splice(i, 1)
          }
        })
      })
      this.Dropslist = date
      cd(data.data.list)
    },

    // 未绑定人员列表
    async Orguserlists(newV, name) {
      const { data } = await Nodeuserusual({ keyword: this.user_name, node_id: this.roleIds, ...this.pageData, })
      // console.log(data)
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
      let date = data.data.list
      this.leftlogData.forEach(item => {
        date.forEach((ele, i) => {
          if (item.user_id == ele.user_id) {
            // delete date[i]
            date.splice(i, 1)
          }
        })
      })
      this.arrs.forEach(item => {
        date.push(item)
      })

      this.rightlogData = date
      // console.log(this.rightlogData)
      var obj = {}
      this.rightlogData = this.rightlogData.reduce(function (item, next) {
        // console.log(item, next)
        obj[next.user_id] ? '' : (obj[next.user_id] = true && item.push(next))
        return item
      }, [])
      // console.log(this.rightlogData, '处理过后')

      this.leftlogData.forEach(item => {
        this.rightlogData.forEach((ele, i) => {
          if (item.user_id == ele.user_id) {
            // delete date[i]
            this.rightlogData.splice(i, 1)
          }
        })
      })
    },

    // 已绑定人员列表
    async Orguserlistsok(newV) {
      const { data } = await Nodeuserlist({ node_id: this.roleIds, circle: '0' })
      // console.log(data, '已绑定')
      this.chaliang = JSON.stringify(data.data)
      this.leftlogData = data.data
      this.Orguserlists()
    },

    // 解除绑定
    async OrgbinduesrNo(resForm) {
      const { data } = await Nodebind({ ...resForm })
      // console.log(data)
      if (data.code == 200) {
        this.$message({
          message: '解除人员绑定成功',
          type: 'success'
        })
        this.arrs = []
        this.$emit('Emits', this.roleIds)
        this.$emit('roleClose')
      }
    },

    // 绑定
    async OrgbinduesrOk(leftForm) {
      const { data } = await Nodebind({ ...leftForm })
      // console.log(data)
      if (data.code == 200) {
        this.$message({
          message: '绑定人员成功',
          type: 'success'
        })
        this.$emit('roleClose')
        this.$emit('Emits', this.roleIds)
        this.arrs = []
      } else {
        this.$message({
          message: `${data.message}`,
          type: 'error'
        })
      }
    },
    iRowStyle: function ({ row, rowIndex }) {
      return 'height:35px'
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return 'height:35px'
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'padding:0px'
    },
    iHeaderCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'padding:0px'
    }
  },
  watch: {
    roleId: {
      handler(newV, oldV) {
        // console.log(newV, 'roleId')
        this.roleIds = newV
      }
    },
    roleVisible: {
      handler(newV, oldV) {
        // console.log(newV, 'roleVisible')
        if (newV) {
          this.Orguserlistsok()
        } else {
          this.pageData.page = 1
          this.pageData.size = 10
          // console.log('ss')
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.dialogOrg {
  width: 100%;
  box-sizing: border-box;
  justify-content: space-around;
  display: flex;
  .dialogOrg-left {
    width: 48%;
  }
  .dialogOrg-right {
    width: 48%;
    .el-select {
      width: 47% !important;
    }
    .shai {
      width: 100%;
      //   border: 1px solid #000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .lianhe {
        width: 49%;
        display: flex;
      }
    }
  }
}
</style>